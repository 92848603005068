.header-button,
.account-button,
.merchant-button {
  font-family: "Montserrat";
  font-weight: 600;
  cursor: pointer;
  color: #4733ff;
  background: #ffffff;
  border: 1px solid #4733ff;
  border-radius: 50px;
  padding: 10px 30px;
}
.account-button,
.merchant-button {
  width: 100%;
  background-color: #4733ff;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
}

.merchant-button {
  width: 45%;
  position: relative;
  right: -53%;
}

.box-container {
  padding: 25px;
  width: 100% !important;
  border: 1px solid #dddddd;
  border-radius: 35px;
}

.box-image {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 25px;
  box-shadow: 0px 0px 0px 6px white inset;
  background-color: #eeeeee;
}
.boxImage {
  height: 50px;
  width: 3vw;
  max-width: 60px;
}

.input-text {
  border-radius: 20px;
  background-color: #fafafa;
  padding-left: 10px !important ;
  width: 90%;
  margin-left: -10px !important;
}
.address {
  width: 95% !important;
}

@media screen and (max-width: 900px) {
  .boxImage {
    height: 50px;
    width: 50px;
  }
}
