.rocketLeft {
  background: -webkit-linear-gradient(#d382de 100%, #9966f3 100%);
}

.rocketRight {
  background: -webkit-linear-gradient(#d382de 0%, #9966f3 100%);
}

.rocketLeft,
.rocketRight {
  font-size: 140px !important;
  font-weight: 700 !important;
  font-style: normal !important;
  font-family: Open Sans !important;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  padding-top: 50px;
}

.rocketAnimation {
  width: 250px;
  height: 550px;
  max-height: 45vh;
  background-size: contain;
  animation-name: rocket;
  animation-duration: 3s;
  background-repeat: no-repeat;
  animation-iteration-count: infinite;
  background-image: url("../../images/error/foguete/Foguete_01.png");
  background-position-x: center;
  background-position-y: bottom;
}

@media screen and (min-width: 0px) and (max-width: 900px) {
  .rocketLeft {
    font-size: 50px !important;
    margin-top: -66px !important;
    margin-right: -35px !important;
  }

  .rocketRight {
    font-size: 50px !important;
    margin-top: 92px !important;
    margin-left: -25px !important;
  }
  .rocketAnimation {
    width: 150px !important;
    background-size: 100%;
  }
}

@keyframes rocket {
  5% {
    background-image: url("../../images/error/foguete/Foguete_02.png");
  }

  20% {
    background-image: url("../../images/error/foguete/Foguete_03.png");
  }

  30% {
    background-image: url("../../images/error/foguete/Foguete_04.png");
  }

  40% {
    background-image: url("../../images/error/foguete/Foguete_05.png");
  }

  50% {
    background-image: url("../../images/error/foguete/Foguete_06.png");
  }

  60% {
    background-image: url("../../images/error/foguete/Foguete_07.png");
  }

  70% {
    background-image: url("../../images/error/foguete/Foguete_08.png");
  }

  80% {
    background-image: url("../../images/error/foguete/Foguete_09.png");
  }

  90% {
    background-image: url("../../images/error/foguete/Foguete_10.png");
  }

  100% {
    background-image: url("../../images/error/foguete/Foguete_01.png");
  }
}
