.novoUsuarioButtonMerchant {
  background-color: #4733ff !important;
  font-weight: bold !important;
  width: 100% !important;
  white-space: nowrap;
}

.novoUsuarioButtonMerchant:hover {
  background-color: #4733fff5 !important;
}

.piperApiListMerchant {
  box-shadow: none !important;
}

.searchLabelMerchant {
  box-shadow: none !important;
}

.piperApiListMerchant button {
  margin-left: -50px !important;
}

.piperApiListMerchant label {
  text-transform: uppercase !important;
}

.piperApiListMerchant input {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: -0.25px !important;
  color: #0f172a !important;
  padding-left: 16px;
  height: 35px;
  border-radius: 20px;
  background: #fafafa;
  padding-right: 50px;
}
