.inter {
  font-family: "Inter" !important;
}
.rounded-numbers {
  background-color: #4733ff;
  border-radius: 50%;
  padding: 0 10px;
  padding-left: 11px;
  color: #ffffff;
  width: 11px;
  height: 30px;
}
.payment-methods-container {
  border: 2px solid #d1d5db;
  border-radius: 20px;
  cursor: pointer;
}
.payment-methods-container:hover {
  border: 2px solid #1a0e38;
}
.invoice-details-container,
.invoice-details-accordion-container .MuiAccordion-root {
  width: 100% !important;
  background-color: #f8f8f8 !important;
  border-radius: 20px !important;
  align-items: center !important;
}

.background-gray {
  background-color: #fafafa !important;
  color: #c4c4cd !important;
}
.input-none-border {
  border: none !important;
  border-radius: 22px !important;
}
.checkout-input {
  margin-bottom: 15px;
  border: 1px solid #c4c4cd !important;
  border-radius: 16px !important;
}

.exchange-container .Mui-focused,
.still-not-kahsh-container .Mui-focused {
  border: 1px solid #1a0e38 !important;
  border-top: 1.4px solid #1a0e38 !important;
}

.checkout-input-2fa {
  background-color: #f2f2f2 !important;
  font-size: 20px !important;
  margin-left: 4px !important;
  padding-left: 5px !important;
}
.checkout-input-2fa fieldset {
  border: none !important;
}
.checkout-button-filled {
  background-color: #4733ff !important;
  border-radius: 10px !important;
  padding: 10px 0 !important;
  color: #ffffff !important;
  text-transform: none !important;
}
.loading {
  background-color: #c4c4cd !important;
}
.checkout-button-outlined {
  border: 2px solid #4733ff !important;
  border-radius: 10px !important;
  padding: 10px 0 !important;
  color: #000000 !important;
  text-transform: none !important;
}
.checkout-button-outlined:hover {
  background-color: #4733ff !important;
  color: #ffffff !important;
}
.here-text {
  transition: 0.3s !important;
}
.checkout-button-outlined:hover .here-text {
  color: #ffffff !important;
}
.copy-content-button {
  text-transform: none !important;
  color: #1a263e !important;
  min-width: 40% !important;
  display: flex !important;
  justify-content: space-around !important;
  background: #f8f8f8 !important;
  border: 1px solid #d1d5db !important;
  border-radius: 12px !important;
}

.checkout-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 400px;
  background-color: #ffffff;
  border-radius: 20px;
  outline: none !important;
  box-shadow: 24;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
.authentication {
  background-color: #4733ff !important;
  border-radius: 20px !important;
  cursor: pointer !important;
}
.authentication img {
  filter: brightness(10%) invert(1);
}

.progress {
  padding: 6px;
  background: #ffffff;
  border-radius: 6px;
}

.progress-bar {
  height: 18px;
  border-radius: 30px;
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-moved .progress-bar {
  width: 85%; 
  background-color: #2c8ac9;  
  animation: progressAnimation 15s;
}

@keyframes progressAnimation {
  0%   { width: 5%; background-color: #5497c4;}
  100% { width: 65%; background-color: #4d96c6;}
}

@media screen and (max-width: 900px) {
  .checkout-modal {
    width: 90%;
    text-align: center;
  }
  .checkout-input-2fa {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 364px) {
  .checkout-input-2fa {
    padding-left: 0;
  }
}
