.side-bar {
  padding-left: 2px;
  height: 100%;
}

.side-bar-title {
  width: 50%;
  vertical-align: middle;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  padding: 1px 4px;
  color: #787a7b;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block !important;
}

.side-bar-image {
  filter: brightness(10%) opacity(30%);
  color: #4733ff;
  margin: 4px 4px;
  vertical-align: middle;
  text-align: center;
}

.side-bar-link:hover {
  border-radius: 9px;
  background: #4733ff;
  white-space: nowrap;
}

.side-bar-link:hover span {
  color: #ffffff;
}

.side-bar-link:hover img {
  filter: brightness(10%) invert(1);
}

.box {
  border: 1px solid #dddddd;
}
.box,
.header-box {
  border-radius: 15px;
}
.header-box {
  background-color: #fafafa;
}
.header-box,
.header-box-responsive {
  text-align: center;
  padding: 20px 0;
  border-radius: 30px;
}
.paid-container-responsive {
  border: 1px solid #dddddd;
  border-radius: 25px;
  padding: 20px 35px;
}
.header-box-responsive {
  padding: 15px 15px 0px 10px !important;
  background-color: #fafafa;
  padding: 0;
  border-radius: 15px;
}
.invoice-box {
  background: #fafafa;
  border-radius: 20px;
  border: 1px solid #ffffff;
}
.header-button,
.header-button-responsive {
  font-family: "Montserrat";
  background-color: #ffffff;
  font-weight: 700;
  margin-top: 8px;
  cursor: pointer;
  color: #4733ff;
  border: 1px solid #4733ff;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 10px 30px;
}

.graph-box-responsive {
  width: 100%;
  border: 1px solid lightgray !important;
  border-radius: 20px;
  padding: 10px;
}

.modal-box-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 24;
  min-width: 680px;
  border-radius: 10px;
  padding: 10px;
}

.MuiPickerStaticWrapper-root {
  margin: 10px !important;
}
.datepicker-box {
  text-align: center;
}
.datepicker-text,
.modal-button {
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
}

.modal-button {
  margin-top: 10px !important;
  min-width: 200px !important;
  background-color: #4733ff !important;
  color: white !important;
}

.datepickerGrid.css-1v4ccyo {
  color: lightgray !important;
  border-radius: 15px !important;
}

.datepicker-field {
  /* border: 1px solid lightgray !important; */
  border-radius: 15px;
  cursor: pointer !important;
  margin-right: 15px !important;
}

.main-content {
  height: 100% !important;
  max-width: 1200px !important;
  width: 100% !important;
}

.pending-invoices-text,
.recent-invoices-text {
  color: #a8a8a8;
  font-size: 24px !important;
}

@media screen and (max-width: 1280px) {
  .side-bar-link {
    margin-top: 0% !important;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1420px) {
  .invoicePaid {
    padding-right: 30px;
  }
}

@media screen and (max-width: 900px) {
  .side-bar {
    display: none !important;
  }
}

@media screen and (max-width: 723px) {
  .modal-box-container {
    min-width: 90%;
  }
}
