.novaApiButton {
  background-color: #4733ff !important;
  font-weight: bold !important;
  width: 100% !important;
  white-space: nowrap;
}

.novaApiButton:hover {
  background-color: #4733fff5 !important;
}

.piperApiList {
  box-shadow: none !important;
}

.searchLabel {
  box-shadow: none !important;
}

.piperApiList button {
  margin-left: -50px !important;
}

.piperApiList label {
  text-transform: uppercase !important;
}

.piperApiList input {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: -0.25px !important;
  color: #0f172a !important;
  padding-left: 16px;
  height: 35px;
  border-radius: 20px;
  background: #fafafa;
  padding-right: 50px;
}

#apiAndVetex .css-3xwl1y-MuiButtonBase-root-MuiTab-root {
  color: #fafafa !important;
  background-color: #ddd;
  height: 100% !important;
  border-radius: 18px 18px 0 0 !important;
}

#apiAndVetex .css-3xwl1y-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000000 !important;
  background-color: #fff;
  border: 1px solid #ddd;
  height: 100% !important;
  border-radius: 18px 18px 0 0 !important;
}

#apiAndVetex .css-1cr4122-MuiTabs-indicator {
  display: none;
}

#apiAndVetex .css-jpln7h-MuiTabs-scroller {
  height: 38px !important;
}
