.listItemLinkWeb span {
  font-weight: 600;
  font-size: 1rem;
}

.listItemUserWeb span {
  font-size: 1rem;
  color: #787a7b;
  font-weight: 600;
  white-space: nowrap;
}

.listItemUserWeb p {
  font-size: 0.7rem;
  color: #787a7b;
  font-weight: 400;
}

.menuDrawer .MuiPaper-root {
  box-shadow: 4px 4px 4px rgb(0 0 0 / 5%);
  border: none;
  width: 256px;
}

.logoImageKahshDrawerWeb {
  width: 160px !important;
  padding-left: 1rem !important;
}
