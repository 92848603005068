.create-invoice,
.invoice-itens,
.transactions {
  border: 1px solid #dddddd;
  border-radius: 25px;
}
.invoice-itens {
  width: 100% !important;
}
.transactions {
  padding-top: 10px;
  height: 300px;
}
.input-text {
  padding: 8px 0 !important;
  margin: 7px 0 18px 0 !important;
}
.input-text-header {
  font-family: "Montserrat" !important;
  font-weight: 300 !important;
  font-size: 13px !important;
  color: #000000 !important;
  margin-left: 25px;
}
.input-props {
  margin-left: 25px !important;
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  color: #000000 !important;
}
.invoice-button,
.add-item-button,
.create-invoice-button,
.add-itens,
.cancelButton {
  background-color: #4733ff !important;
  color: #ffffff !important;
  cursor: pointer !important;
  font-size: 25px !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: 10px !important;
  padding: 10px 30px !important;
}
.add-itens {
  padding: 5px !important;
}

.cancelButton {
  box-sizing: border-box;
  border: 2px solid #4733ff;
  background-color: #ffffff;
  color: #4733ff;
  font-weight: 500;
}
.deleteButton {
  box-sizing: border-box;
  border: 2px solid #4733ff;
  font-weight: 500;
}
.remove-itens {
  background-color: #cd0808;
  color: #ffffff;
  cursor: pointer;
  font-size: 25px;
  font-weight: 700;
  border: none;
  border-radius: 10px;
}
.itensButton {
  padding: 4px 0px;
  width: 4vw;
  height: 5vh;
  margin: 10px;
  text-align: center;
}
.icons {
  font-size: 35px !important;
  text-align: center;
  padding: 2px 0px;
  width: 4vw;
  height: 5vh;
}

.modalBoxDelete {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 24;
  border-radius: 40px;
  padding: 50px;
}

#deletePerspImg {
  max-width: 100%;
  max-height: 100%;
}

.create-invoice-button {
  min-width: 350px;
}
.add-item-button {
  width: 100%;
  max-width: 300px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.invoice-button-fixed {
  /* width: 80% !important; */
  /* position: fixed !important; */
  z-index: 9999 !important;
  bottom: 0;
  /* margin-bottom: 20px !important; */
}
.button-transaction {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 12px 30px;
  cursor: pointer;
}
.input-select {
  padding: 0 !important;
}

.disabled {
  background-color: #dddddd !important;
}

@media screen and (max-width: 900px) {
  .itensButton {
    padding: 2px 0px;
    width: 40px;
    height: 40px;
    margin: 10px;
    text-align: center;
  }
  .icons {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 600px) {
  .create-invoice {
    padding: 40px 20px !important;
  }
  .add-item-button {
    max-width: 80%;
  }
  .create-invoice-text,
  .add-item-container {
    text-align: center;
  }
  /* .invoice-button-fixed {
    width: 100% !important;
    justify-content: center !important;
  } */
  .create-invoice-button {
    min-width: 0 !important;
  }
}
